// Avoid `console` errors in browsers that lack a console.
(function() {
  var method;
  var noop = function() {};
  var methods = ['assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error', 'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log', 'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd', 'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'];
  var length = methods.length;
  var console = (window.console = window.console || {});

  while (length--) {
    method = methods[length];

    // Only stub undefined methods.
    if (!console[method]) {
      console[method] = noop;
    }
  }
}());
if (typeof jQuery === 'undefined') {
  console.warn('jQuery hasn\'t loaded');
} else {
  console.log('jQuery has loaded');
}
// Place any jQuery/helper plugins in here.


function openbox(id,tt) {
              var div = document.getElementById(id);
              var tt_div = document.getElementById(tt);
              if(div.style.display == 'block') {
                div.style.display = 'none';
              }
              else {
                div.style.display = 'block';
              }
              }






$(document).ready(function(){

$(".center").slick({
        dots: true,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        centerPadding: '500px',
         autoplay: true,
         autoplaySpeed: 1500,
        slidesToScroll: 1,
        responsive: [
            {
            breakpoint: 1600,
            settings: {
              centerPadding: '230px'
            }
          },
          {
            breakpoint: 800,
            settings: {
              centerPadding: '130px'
            }
          },
          {
            breakpoint: 500,
            settings: {
              centerPadding: '30px'
            }
          }

        ]
      });

});



$(".top_head").addClass("animation", 100);
$(document).ready(function(){
  $(".scroll_b").on("click", function (event) {
    //отменяем стандартную обработку нажатия по ссылке
    event.preventDefault();

    //забираем идентификатор бока с атрибута href
    var id  = $(this).attr('href'),

    //узнаем высоту от начала страницы до блока на который ссылается якорь
      top = $(id).offset().top;

    //анимируем переход на расстояние - top за 1500 мс
    $('body,html').animate({scrollTop: top}, 1500);
  });
});

$(".top_head").addClass("animation", 100);
$(document).ready(function(){
  $(".top_head_left .button").on("click", function (event) {
    //отменяем стандартную обработку нажатия по ссылке
    event.preventDefault();

    //забираем идентификатор бока с атрибута href
    var id  = $(this).attr('href'),

    //узнаем высоту от начала страницы до блока на который ссылается якорь
      top = $(id).offset().top;

    //анимируем переход на расстояние - top за 1500 мс
    $('body,html').animate({scrollTop: top}, 1500);
  });
});




jQuery(document).ready(function() {
  jQuery('.top_txt_item .title,.top_txt_item p').addClass("hidden").viewportChecker({
      classToAdd: 'visible animated fadeIn', // Class to add to the elements when they are visible
      offset: 50
     });

  jQuery('.top_txt_item .border').addClass("hidden").viewportChecker({
      classToAdd: 'visible animated bounceInLeft', // Class to add to the elements when they are visible
      offset: 50
     });

  jQuery('.top_txt_item .see_all').addClass("hidden").viewportChecker({
      classToAdd: 'visible animated fadeIn', // Class to add to the elements when they are visible
      offset: 50
     });



jQuery('.top_txt_right_item').addClass("no_an").viewportChecker({
      classToAdd: 'animation', // Class to add to the elements when they are visible
      offset: 50
     });


  jQuery('.black_fon .title').addClass("hidden").viewportChecker({
      classToAdd: 'visible animated fadeIn', // Class to add to the elements when they are visible
      offset: 50
     });

  jQuery('.black_fon_top ul li').addClass("hidden").viewportChecker({
      classToAdd: 'visible animated fadeIn', // Class to add to the elements when they are visible
      offset: 50
     });

  jQuery('.black_fon_bottom ul li span').addClass("hidden").viewportChecker({
      classToAdd: 'visible animated fadeIn', // Class to add to the elements when they are visible
      offset: 50
     });

  jQuery('.black_fon_bottom ul li p').addClass("hidden").viewportChecker({
      classToAdd: 'visible animated fadeIn', // Class to add to the elements when they are visible
      offset: 50
     });

  jQuery('.black_fon_sqwer').addClass("no_an").viewportChecker({
      classToAdd: 'animation', // Class to add to the elements when they are visible
      offset: 550
     });





});


$('.one-time').slick({
  dots: true,
  infinite: true,
  speed: 300,
  slidesToShow: 1,
  adaptiveHeight: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 2000,
  fade: true,
  cssEase: 'linear'
});
